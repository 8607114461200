import { KBarProvider } from 'kbar';
// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Portal from './Pages/Portal';
import RequestInterceptor from './utils/RequestInterceptor';
import './i18n/i18n';

import React from 'react';
import FullPageLoader from './common/FullPageLoader';

function App({ pca }: { pca: IPublicClientApplication }) {
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <MsalProvider instance={pca}>
        <RequestInterceptor>
          <KBarProvider>
            <Portal />
          </KBarProvider>
        </RequestInterceptor>
      </MsalProvider>
    </React.Suspense>
  );
}

export default App;
